* {
  user-select: none;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  background: #000;
  color: #fff;
  overflow: hidden;
  position: fixed;
}

html, body, #root, .App, main, .ytplayer-container {
  height: calc(100% - 32pt);
}

input {
  background: #000;
  border: 1px solid #fff;
  border-right: 0;
  border-radius: 0;
  color: #fff;
  font-weight: 700;
  outline: none;
  user-select: text;
}

input::placeholder {
  color: #434343
}

button {
  background: #000;
  border: 1px solid #fff;
  border-radius: 0;
  color: #fff;
  cursor: pointer;
  font-weight: 700;
  outline: none;
}