.Decks {
    display: block;
    height: calc(100% + 16pt);
}

form {
    position: absolute;
    bottom: 0;
    width: 100%;
}

@media (min-width: 800px) {
    form {
        position: relative;
        width: 100%;
    }
}

.Decks h2 {
    padding-bottom: 12pt;
}

.Decks-input-container {
    padding-top: 12pt;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media (min-width: 800px) {
    .Decks-input-container {
        width: 50%;
        padding: 0% 30%;
        padding-top: 12pt;
    }
}

.Decks-input-container button {
    font-size: 12pt;
    width: 25%;
    padding: 10pt;
}

.Decks-input-container input {
    font-size: 12pt;
    width: 75%;
    padding: 10pt;
}

.Decks-controls {
    margin-top: 10vh;
    display: flex;
    justify-content: space-around;
}

.Decks-toggle-label {
    margin-right: 15px;
}

.Decks-controls-open-decks {
    display: flex;
    justify-content: center;
    align-items: center;
}