.ListenerCount {
    background: #000000;
    border: 1px solid #ffffff;
    border-bottom: none;
    position: fixed;
    top: 32pt;
    right: 0;
    z-index: 10010;
    font-weight: bold;
    font-size: 14pt;
    width: 32pt;
    height: 32pt;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.ListenerCount.listeners {
    width: unset;
    padding: 0 12pt;
    border-bottom: 1px solid white;
    top: calc(32pt - 1px);
    z-index: 1000000;
}