#ytplayer {
  position: absolute;
  width: 100%;
  height: calc(100% - 32pt);
  top: 32pt;
  left: 0;
}
  
.ytplayer-container.ytplayer-small {
  height: unset;
  margin-top: 5vh;
}
  
@media (min-width: 800px) {
  .ytplayer-container.ytplayer-small {
    height: 50%;
  }
}

.ytplayer-container.ytplayer-small #ytplayer {
  position: relative;
  width: unset;
  height: unset;
  top: unset;
  left: unset;
}