.QueueCount {
    top: 64pt;
}

.QueueCount.long {
    padding-bottom: 32pt;
    border-bottom: 1px solid #ffffff;
}

.QueueCount .Drawer {
    min-width: 10vw;
    background: #000000;
    color: #ffffff;
    border: 1px solid #ffffff;
    top: calc(32pt - 1px);
    right: calc(32pt + 1px);
    padding: 8pt;
    display: flex;
    flex-direction: column;
    font-size: 12pt;
    position: fixed;
    min-height: calc(32pt + 32pt + 32pt - 16pt + 1px);
}

.QueueCount .Drawer .scrollable {
    max-height: calc(70vh - 74pt - 10px);
    overflow: scroll;
}

.QueueCount .Drawer .heading {
    font-size: 14pt;
    padding-bottom: 8pt;
}

.QueueCount .Drawer .DrawerItem {
    align-items: center;
    display: flex;
}

.QueueCount .Drawer .DrawerItem .index {
    align-items: center;
    display: flex;
    font-size: 10pt;
    height: 40px;
    justify-content: center;
    width: 40px;
}

.QueueCount .Drawer .DrawerItem a {
    text-decoration: none;
}

.QueueCount .queueCount {
    z-index: 10;
}