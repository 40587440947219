header {
    position: absolute;
    top: 0;
    left: 0;
    height: 32pt;
    background: #000;
    color: #fff;
    width: 100%;
    z-index: 1000;
    width: 100%;
}

.Menu {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 100%;
}

.Menu a,
.Menu a:visited,
.Menu a:active,
.Menu a:focus {
    outline: none;
    text-decoration: none;
    color: #fff;
    font-size: 16pt;
    line-height: 16pt;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0;
    margin: 0;
    margin-top: -2pt;
}

.Menu a.vj-name {
    font-weight: normal;
    cursor: default;
}

.Menu a.vj-name.current,
.Menu a.watch.current,
.Menu a.decks.current {
    color: #F5D547;
}

.Menu a.watch:hover,
.Menu a.decks:hover {
    font-style: italic;
}

.enter-button {
    padding: 10pt 20pt;
    font-size: 16pt;
}